import { GridCellProps } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';

import { RoomType } from '../../../types/types';
import CustomGridCell from './custom-grid-cell';

const LuxuryCell = (gridCellProps: GridCellProps) => {
  const { t } = useTranslation();
  const { dataItem } = gridCellProps;
  const { luxuryLevel } = dataItem as RoomType;

  return <CustomGridCell gridCellProps={gridCellProps}>{t(`luxury-level.${luxuryLevel}`)}</CustomGridCell>;
};

export default LuxuryCell;
