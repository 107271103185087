const generateFormData = (files: File[], isUpdate = false): FormData => {
  const formData = new FormData();
  const key = isUpdate ? 'Image' : 'Images';

  files.forEach((file) => {
    formData.append(key, file);
  });

  return formData;
};

export default generateFormData;
