import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from '@progress/kendo-react-labels';
import { Button } from '@progress/kendo-react-buttons';

import { xIcon } from '@progress/kendo-svg-icons';
import { Field } from '@progress/kendo-react-form';
import { handleDeleteImage, handleUpdateImage } from '../utils/edit-form-handlers';
import FileUpload from '../../../../../shared/components/form/file-upload/file-upload';
import { ResourceLinkType } from '../../../../../shared/types/types';

type ImageContainerProps = {
  imageName: string;
  index: number;
  onChange: (name: string, options: { value: unknown }) => void;
  valueGetter: (name: string) => unknown;
  showImage: boolean;
  fileData: string | undefined;
  resourceLinks: ResourceLinkType[];
  setShowImage: Dispatch<SetStateAction<boolean>>;
  setFileData: Dispatch<SetStateAction<string | undefined>>;
};

const ImageContainer = ({
  imageName,
  index,
  onChange,
  valueGetter,
  showImage,
  fileData,
  resourceLinks,
  setShowImage,
  setFileData,
}: ImageContainerProps) => {
  const resourceLink = resourceLinks && resourceLinks.find((resource) => resource.id === index + 1);
  const { t } = useTranslation();

  return (
    <div
      className="form-item"
      key={imageName}
    >
      <Label>{t('generic.image', { index: index + 1 })}</Label>
      {resourceLink && !fileData && showImage && (
        <div className="image">
          <img
            src={resourceLink.url}
            alt=""
          />
          <div className="delete-icon">
            <Button
              onClick={() => handleDeleteImage(index + 1, onChange, valueGetter, setShowImage)}
              svgIcon={xIcon}
            />
          </div>
        </div>
      )}
      {fileData && (
        <div className="image">
          <img
            src={fileData}
            alt=""
          />
        </div>
      )}
      <div className="k-form-field">
        <Field
          name={imageName}
          component={FileUpload}
          onChange={({ target, value }: { target: string; value: File }) => {
            onChange(target, { value });

            handleUpdateImage(index + 1, onChange, valueGetter, resourceLinks!);

            const reader = new FileReader();

            reader.onloadend = () => {
              setFileData(reader.result as string);
            };

            if (value) {
              reader.readAsDataURL(value);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ImageContainer;
