import { PrepareType, UpdateFileType } from '../../../shared/types/types';

const prepareForSubmit = <T>(
  item: T & {
    file1?: File;
    file2?: File;
    file3?: File;
    removeImages?: number[];
    updateImages?: number[];
  },
): PrepareType<T> => {
  const { file1, file2, file3, removeImages, updateImages } = item;
  const filesToUpdate: UpdateFileType[] = [];
  const filesToAdd: File[] = [];

  if (file1) {
    if (updateImages?.includes(1)) {
      filesToUpdate.push({ id: 1, file: file1 });
    } else {
      filesToAdd.push(file1);
    }
  }
  if (file2) {
    if (updateImages?.includes(2)) {
      filesToUpdate.push({ id: 2, file: file2 });
    } else {
      filesToAdd.push(file2);
    }
  }
  if (file3) {
    if (updateImages?.includes(3)) {
      filesToUpdate.push({ id: 3, file: file3 });
    } else {
      filesToAdd.push(file3);
    }
  }

  const filesToRemove = removeImages ?? [];

  /* eslint-disable no-param-reassign */
  delete item.file1;
  delete item.file2;
  delete item.file3;
  delete item.removeImages;
  delete item.updateImages;
  delete (item as any).resourceLinks;
  /* eslint-enable no-param-reassign */

  return { item, filesToAdd, filesToUpdate, filesToRemove };
};

export default prepareForSubmit;
