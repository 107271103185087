import React from 'react';

export type PageType = {
  children: React.ReactNode;
  className?: string;
  theme?: PageThemeEnum;
  showNavigation?: boolean;
};

export type PageContentType = {
  children: React.ReactNode;
  className?: string;
  theme?: PageThemeEnum;
};

export type PageNavbarType = {
  theme: PageThemeEnum;
  showNavigation?: boolean;
};

export enum PageThemeEnum {
  Default = 'default',
  BigCard = 'big-card',
}
