import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import Page from '../../../shared/components/layout/page/page';
import { PageThemeEnum } from '../../../shared/components/layout/page/types';
import CommandCell from '../../../shared/components/grid/cells/command-cell';
import { AccommodationType, FormErrorType } from '../../../shared/types/types';
import BooleanCell from '../../../shared/components/grid/cells/boolean-cell';
import EditForm from '../components/edit-form/accommodations/edit-form';
import DataLoader from '../../../shared/components/grid/data-loader/data-loader';
import { deleteFiles, get, put, saveFiles, updateFiles } from '../data/accommodations-service';
import prepareForSubmit from '../data/submit-handler';
import TextCell from '../../../shared/components/grid/cells/text-cell';

const AccommodationsPage = () => {
  const navigate = useNavigate();
  const { bemClassName } = useBem('accommodations-page');
  const { t } = useTranslation();

  const [openForm, setOpenForm] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formErrors, setFormErrors] = React.useState<FormErrorType | undefined>();
  const [editItem, setEditItem] = React.useState<AccommodationType>();
  const [data, setData] = useState<DataResult>({
    data: [],
    total: 0,
  });
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
    sort: [{ field: 'name', dir: 'asc' }],
  });

  const enterEdit = (item: AccommodationType) => {
    setOpenForm(true);
    setEditItem(item);
  };

  const updateAction = async (accommodation: AccommodationType) => {
    const result = await put(accommodation);

    data.data = data.data.map((item) => {
      if (accommodation.id === item.id) {
        // eslint-disable-next-line no-param-reassign
        item = { ...result };
      }

      return item;
    });
    setData({ ...data });
  };

  const handleSubmit = async (accommodation: AccommodationType) => {
    setLoading(true);
    const { item, filesToAdd, filesToUpdate, filesToRemove } = prepareForSubmit<AccommodationType>(accommodation);
    const { id } = item;

    if (filesToRemove.length > 0) {
      await deleteFiles(id, filesToRemove);
    }

    if (filesToAdd.length > 0) {
      await saveFiles(id, filesToAdd);
    }

    if (filesToUpdate.length > 0) {
      await updateFiles(id, filesToUpdate);
    }

    updateAction(item)
      .then(() => setOpenForm(false))
      .catch((error: AxiosResponse) => {
        if (error.status !== 422) {
          const {
            data: { errors, detail },
          } = error as { data: { errors: { [k in string]: string[] }; detail: string } };
          setFormErrors({
            title: detail,
            messages: errors,
          });
        } else {
          setFormErrors({ title: t('generic.error') });
        }
      })
      .finally(() => setLoading(false));
  };

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const dataReceived = (dataResult: DataResult) => {
    if (dataResult.data) {
      setData({ ...dataResult });
    } else {
      setData({ data: [], total: 0 });
    }
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const gridCommandCell = (gridCellProps: GridCellProps) => {
    const { dataItem } = gridCellProps;
    const { name, id: dataItemId } = dataItem as AccommodationType;

    const actions = [
      {
        text: t('generic.edit'),
        action: () => enterEdit(dataItem as AccommodationType),
      },
      {
        text: t('accommodations.rooms'),
        action: () => navigate(`/accommodations/${dataItemId}/rooms/${name}`),
      },
    ];

    return (
      <CommandCell
        gridCellProps={gridCellProps}
        actions={actions}
      />
    );
  };

  return (
    <Page
      theme={PageThemeEnum.Default}
      className={bemClassName()}
      showNavigation
    >
      <h1 className={bemClassName('title')}>{t('accommodations.title')}</h1>
      <div className={bemClassName('content')}>
        <Grid
          filterable
          sortable
          pageable
          {...dataState}
          data={data}
          onDataStateChange={dataStateChange}
        >
          <GridColumn
            field="name"
            width={300}
            title={t('accommodations.name')}
          />
          <GridColumn
            field="description"
            title={t('accommodations.description')}
            cell={TextCell}
          />
          <GridColumn
            field="address.city"
            width={200}
            title={t('accommodations.city')}
          />
          <GridColumn
            field="isPrimary"
            filter="boolean"
            cell={BooleanCell}
            width={150}
            title={t('accommodations.is-primary')}
          />
          <GridColumn
            field="isOverflow"
            filter="boolean"
            cell={BooleanCell}
            width={150}
            title={t('accommodations.is-overflow')}
          />
          <GridColumn
            cell={gridCommandCell}
            title=""
            width={150}
            filterable={false}
          />
        </Grid>

        {openForm && (
          <EditForm
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            item={editItem!}
            error={formErrors}
            loading={loading}
          />
        )}

        <DataLoader
          get={get}
          dataState={dataState}
          onDataReceived={dataReceived}
        />
      </div>
    </Page>
  );
};

export default AccommodationsPage;
