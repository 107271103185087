import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType, useEffect } from 'react';
import http from '../../shared/utils/http-client';
import Loader from '../../shared/components/loader/loader';
import Page from '../../shared/components/layout/page/page';

type ProtectedRouteProps = {
  component: ComponentType;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }: ProtectedRouteProps) => {
  const loader = () => <Page><Loader /></Page>;
  const { getAccessTokenSilently } = useAuth0();

  // taken from: https://stackoverflow.com/a/68407340
  useEffect(() => {
    http.tokenGenerator = getAccessTokenSilently;
  }, [getAccessTokenSilently]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: loader,
  });

  return <Component />;
};

export default ProtectedRoute;
