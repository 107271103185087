import { useTranslation } from 'react-i18next';
import { SvgIcon } from '@progress/kendo-react-common';
import DateFormatter from '../../../../shared/utils/format-date';
import determineBooleanIcon from '../../../../shared/utils/determine-boolean-icon';
import { BookingType } from '../../types';
import DisplayGridRecord from '../display-grid/display-grid-record';
import DisplayGrid from '../display-grid/display-grid';
import ContactInformationRecords from '../contact-information/contact-information-records';

type PropsType = {
  booking: BookingType;
};

const MainBookingDetails = ({
  booking: {
    amountOfPeople,
    bookingStatus,
    contactInformation,
    contactWithHotel,
    createdAt,
    from,
    id,
    longerStay,
    longerStayDescription,
    reserveConferenceRoom,
    to,
    updatedAt,
  },
}: PropsType) => {
  const { t } = useTranslation();

  return (
    <DisplayGrid>
      <DisplayGridRecord label={t('booking-details.id')}>{id}</DisplayGridRecord>
      <DisplayGridRecord label={t('booking-details.amount-of-people')}>{amountOfPeople}</DisplayGridRecord>
      <DisplayGridRecord label={t('booking-details.from')}>
        <DateFormatter date={from} />
      </DisplayGridRecord>
      <DisplayGridRecord label={t('booking-details.to')}>
        <DateFormatter date={to} />
      </DisplayGridRecord>
      <DisplayGridRecord label={t('booking-details.longer-stay')}>
        <SvgIcon icon={determineBooleanIcon(longerStay)} />
      </DisplayGridRecord>
      {longerStay && (
        <DisplayGridRecord label={t('booking-details.longer-stay-description')}>
          {longerStayDescription}
        </DisplayGridRecord>
      )}
      <DisplayGridRecord label={t('booking-details.reserve-conference-room')}>
        <SvgIcon icon={determineBooleanIcon(reserveConferenceRoom)} />
      </DisplayGridRecord>
      <DisplayGridRecord label={t('booking-details.contact-with-hotel')}>
        <SvgIcon icon={determineBooleanIcon(contactWithHotel)} />
      </DisplayGridRecord>
      {contactInformation && <ContactInformationRecords contactInformation={contactInformation} />}
      <DisplayGridRecord label={t('booking-details.booking-status')}>{bookingStatus}</DisplayGridRecord>
      <DisplayGridRecord label={t('generic.created-at')}>
        <DateFormatter
          date={createdAt}
          showTime
        />
      </DisplayGridRecord>
      <DisplayGridRecord label={t('generic.updated-at')}>
        <DateFormatter
          date={updatedAt}
          showTime
        />
      </DisplayGridRecord>
    </DisplayGrid>
  );
};

export default MainBookingDetails;
