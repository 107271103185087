import { useRef } from 'react';
import toSieveString from '@indicia/kendo-sieve-processors';
import { DataResult, State, toODataString } from '@progress/kendo-data-query';
import LoadingPanel from '../../loading-panel/loading-panel';

interface GridLoaderProps {
  dataState: State;
  onDataReceived: (data: DataResult) => void;
  get: (sieveString: string) => Promise<any>;
}

const DataLoader = ({ dataState, get, onDataReceived }: GridLoaderProps) => {
  const lastSuccess = useRef<string>('');
  const pending = useRef<string>('');

  const requestDataIfNeeded = () => {
    if (pending.current || toODataString(dataState) === lastSuccess.current) {
      return;
    }

    const sieveString = toSieveString(dataState);
    pending.current = toODataString(dataState);

    get(sieveString).then((response) => {
      lastSuccess.current = pending.current;
      pending.current = '';

      if (toODataString(dataState) === lastSuccess.current) {
        onDataReceived({
          data: response.data,
          total: Number.parseInt(response.headers['x-total-count'], 10),
        });
      } else {
        requestDataIfNeeded();
      }
    });
  };

  requestDataIfNeeded();
  return pending.current ? <LoadingPanel /> : null;
};

export default DataLoader;
