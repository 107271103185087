import { useInternationalization } from '@progress/kendo-react-intl';

type PropsType = {
  date: string;
  showTime?: boolean;
};

const DateFormatter = ({ date, showTime = false }: PropsType) => {
  if (date === null) return null;

  const intl = useInternationalization();
  return <>{intl.formatDate(new Date(date), showTime ? { datetime: 'short' } : { date: 'short' })}</>;
};

export default DateFormatter;
