import { GridCellProps } from '@progress/kendo-react-grid';

import DateFormatter from '../../../utils/format-date';
import CustomGridCell from './custom-grid-cell';

const DateCell = (gridCellProps: GridCellProps) => {
  const { dataItem, field } = gridCellProps;

  return (
    <CustomGridCell gridCellProps={gridCellProps}>
      <DateFormatter date={dataItem[field!]} />
    </CustomGridCell>
  );
};

export default DateCell;
