import useBem from '@indicia/use-bem';

import { MenuLogoType } from './types';
import { PageThemeEnum } from '../../../types';

import './menu-logo.scss';

const MenuLogo = ({ theme = PageThemeEnum.Default }: MenuLogoType) => {
  const { bemClassName, bemClassNames } = useBem('menu-logo');

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(null, theme)])}>
      <img
        src="assets/images/NATOhor_RGB.jpg"
        alt="NATO Logo"
      />
      <img
        src="assets/images/worldmeetings-dark.svg"
        alt="Worldmeetings logo"
      />
    </div>
  );
};

export default MenuLogo;
