import { GridCellProps } from '@progress/kendo-react-grid';
import { SvgIcon } from '@progress/kendo-react-common';

import determineBooleanIcon from '../../../utils/determine-boolean-icon';
import CustomGridCell from './custom-grid-cell';

const BooleanCell = (gridCellProps: GridCellProps) => {
  const { dataItem, field } = gridCellProps;

  return (
    <CustomGridCell gridCellProps={gridCellProps}>
      <SvgIcon icon={determineBooleanIcon(dataItem[field!])} />
    </CustomGridCell>
  );
};

export default BooleanCell;
