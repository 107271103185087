import useBem from '@indicia/use-bem';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Container } from '../../../../index';
import MenuLogo from './components/menu-logo';
import { PageNavbarType, PageThemeEnum } from '../../types';

import './page-navbar.scss';

const PageNavbar = ({ theme, showNavigation = false }: PageNavbarType) => {
  const { bemClassName, bemClassNames } = useBem('navbar');
  const { t } = useTranslation();
  const { logout } = useAuth0();

  return (
    <nav className={bemClassNames([bemClassName(), bemClassName(null, theme)])}>
      <Container>
        <div className={bemClassName('items')}>{theme !== PageThemeEnum.BigCard && <MenuLogo />}</div>

        {showNavigation && (
          <div className={bemClassName('actions')}>
            <NavLink to="/delegations">
              <Button>{t('homepage.delegations')}</Button>
            </NavLink>
            <NavLink to="/bookings">
              <Button>{t('homepage.bookings')}</Button>
            </NavLink>
            <NavLink to="/accommodations">
              <Button>{t('homepage.accommodations')}</Button>
            </NavLink>
            <Button onClick={() => logout()}>Logout</Button>
          </div>
        )}
      </Container>
    </nav>
  );
};

export default PageNavbar;
