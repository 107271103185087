import http from '../../../shared/utils/http-client';
import { multiPartFormConfig, updateConfig } from '../../../shared/utils/http-configs';
import { AccommodationType, UpdateFileType } from '../../../shared/types/types';
import generateFormData from '../../../shared/utils/file-utils';

const updateFile = async (id: string, { id: fileId, file }: UpdateFileType) => {
  const formData = generateFormData([file], true);

  await http.put(`accommodations/${id}/images/${fileId}`, formData, multiPartFormConfig);
};

const updateFiles = async (roomId: string, files: UpdateFileType[]) => {
  await Promise.all(files.map((file) => updateFile(roomId, file)));
};

const saveFiles = async (id: string, files: File[]) => {
  const formData = generateFormData(files);

  await http.post(`accommodations/${id}/images`, formData, multiPartFormConfig);
};

const deleteFile = async (id: string, fileId: number) => {
  await http.delete(`accommodations/${id}/images/${fileId}`);
};

const deleteFiles = async (roomId: string, files: number[]) => {
  await Promise.all(files.map((fileId) => deleteFile(roomId, fileId)));
};

const get = async (searchParams: string) => http.get(`accommodations?${searchParams}`);

const put = async (accommodation: AccommodationType) => {
  const { id } = accommodation;
  const { data } = await http.put(`accommodations/${id}`, accommodation, updateConfig);

  return data;
};

export { get, put, deleteFiles, saveFiles, updateFiles };
