import { FieldRenderProps } from '@progress/kendo-react-form';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';

const ValidatedNumericInput = ({ validationMessage, touched, id, ...others }: FieldRenderProps) => {
  const showValidationMessage: string | false | null = touched && validationMessage;
  const errorId: string = showValidationMessage ? `${id}_error` : '';

  return (
    <div>
      <NumericTextBox {...others} />
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  );
};

export default ValidatedNumericInput;
