import { ReactNode } from 'react';
import { GRID_COL_INDEX_ATTRIBUTE, GridCellProps } from '@progress/kendo-react-grid';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';

type PropsType = {
  gridCellProps: GridCellProps;
  children: ReactNode;
};

const CustomGridCell = ({
  children,
  gridCellProps: { id, colSpan, ariaColumnIndex, isSelected, columnIndex },
}: PropsType) => {
  const navigationAttributes = useTableKeyboardNavigation(id);

  return (
    <td
      colSpan={colSpan ?? 1}
      className="k-table-td"
      role="gridcell"
      aria-colindex={ariaColumnIndex}
      aria-selected={isSelected}
      {...{ [GRID_COL_INDEX_ATTRIBUTE]: columnIndex }}
      {...navigationAttributes}
    >
      {children}
    </td>
  );
};

export default CustomGridCell;
