import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { IntlProvider, load } from '@progress/kendo-react-intl';

/* CLDR Data */
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import nlNumbers from 'cldr-numbers-full/main/nl/numbers.json';
import nlLocalCurrency from 'cldr-numbers-full/main/nl/currencies.json';
import nlCaGregorian from 'cldr-dates-full/main/nl/ca-gregorian.json';
import nlDateFields from 'cldr-dates-full/main/nl/dateFields.json';

import '@progress/kendo-theme-default/dist/all.css';
import '@indicia/component-library/style.css';
import './shared/styling/global.scss';
import './shared/styling/variables.scss';
import './shared/styling/fonts.scss';
import './shared/styling/typography.scss';

load(likelySubtags, currencyData, weekData, nlNumbers, nlLocalCurrency, nlCaGregorian, nlDateFields);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>{error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <IntlProvider locale="nl">
      <QueryClientProvider client={queryClient}>
        <Outlet />
      </QueryClientProvider>
    </IntlProvider>
  );
};

export default App;
