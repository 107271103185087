import { useTranslation } from 'react-i18next';
import DisplayGridRecord from '../display-grid/display-grid-record';
import { ContactInformationType } from '../../types';

type PropsType = {
  contactInformation: ContactInformationType;
};

const ContactInformationRecords = ({ contactInformation: { emailAddress, phoneNumber } }: PropsType) => {
  const { t } = useTranslation();

  return (
    <>
      <DisplayGridRecord label={t('generic.email')}>{emailAddress}</DisplayGridRecord>
      <DisplayGridRecord label={t('generic.phone-number')}>{phoneNumber}</DisplayGridRecord>
    </>
  );
};

export default ContactInformationRecords;
