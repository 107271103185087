import http from '../../../shared/utils/http-client';
import { multiPartFormConfig, updateConfig } from '../../../shared/utils/http-configs';
import { RoomType, UpdateFileType } from '../../../shared/types/types';
import generateFormData from '../../../shared/utils/file-utils';

const updateFile = async (accommodationId: string, roomId: string, { id, file }: UpdateFileType) => {
  const formData = generateFormData([file], true);

  await http.put(`accommodations/${accommodationId}/room-types/${roomId}/images/${id}`, formData, multiPartFormConfig);
};

const updateFiles = async (accommodationId: string, roomId: string, files: UpdateFileType[]) => {
  await Promise.all(files.map((file) => updateFile(accommodationId, roomId, file)));
};

const saveFiles = async (accommodationId: string, roomId: string, files: File[]) => {
  const formData = generateFormData(files);

  await http.post(`accommodations/${accommodationId}/room-types/${roomId}/images`, formData, multiPartFormConfig);
};

const deleteFile = async (accommodationId: string, roomId: string, resourceId: number) => {
  await http.delete(`accommodations/${accommodationId}/room-types/${roomId}/images/${resourceId}`);
};

const deleteFiles = async (accommodationId: string, roomId: string, files: number[]) => {
  await Promise.all(files.map((fileId) => deleteFile(accommodationId, roomId, fileId)));
};

const get = async (accommodationId: string, searchParams: string) =>
  http.get(`accommodations/${accommodationId}/room-types?${searchParams}`);

const put = async (accommodationId: string, room: RoomType) => {
  const { id } = room;
  const { data } = await http.put(`accommodations/${accommodationId}/room-types/${id}`, room, updateConfig);

  return data;
};

export { get, put, deleteFiles, saveFiles, updateFiles };
