import { Dispatch, SetStateAction } from 'react';
import { ResourceLinkType } from '../../../../../shared/types/types';

const handleDeleteImage = (
  index: number,
  onChange: (name: string, options: { value: unknown }) => void,
  valueGetter: (name: string) => unknown,
  setShowImage: Dispatch<SetStateAction<boolean>>,
) => {
  const images = valueGetter('removeImages') as [];
  const value = images ? [...images, index] : [index];

  setShowImage(false);
  onChange('removeImages', { value });
};

const handleUpdateImage = (
  index: number,
  onChange: (name: string, options: { value: unknown }) => void,
  valueGetter: (name: string) => unknown,
  resourceLinks: ResourceLinkType[],
) => {
  const images = valueGetter('updateImages') as number[];
  const removeImages = valueGetter('removeImages') as number[];

  if (removeImages?.includes(index)) {
    onChange('removeImages', { value: removeImages?.filter((n: number) => n !== index) });
  }

  if (resourceLinks?.some(({ id }: ResourceLinkType) => id === index)) {
    const value = images ? [...images, index] : [index];
    onChange('updateImages', { value });
  }
};

export { handleDeleteImage, handleUpdateImage };
