import {FC, useState} from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { useTranslation } from 'react-i18next';
import { InfinitySpin } from 'react-loader-spinner';
import {v4} from "uuid";

import { RoomType } from '../../../../../shared/types/types';
import { EditFormProps } from '../types';
import ValidatedTextArea from '../../../../../shared/components/form/validated-textarea/validated-textarea';
import ValidatedNumericInput from '../../../../../shared/components/form/validated-numeric-input/validated-numeric-input';
import ValidatedInput from "../../../../../shared/components/form/validated-input/validated-input";
import ImagesList from '../components/images-list';
import validator from './validator';

import '../css/edit-form.scss';

const EditForm: FC<EditFormProps<RoomType>> = ({ item, cancelEdit, onSubmit, error = undefined, loading = false }) => {
  const { luxuryLevel, resourceLinks, amenities } = item;
  const { t } = useTranslation();
  const [amenitiesList, setAmenitiesList] = useState(amenities.map(amenity => ({ id: v4(), value: amenity })) || []);
  const [amenityRemoved, setAmenityRemoved] = useState(false);

  const handleAddAmenity = () => {
    setAmenitiesList([...amenitiesList, { id: v4(), value: '' }]);
  };

  const handleAmenityChange = (id: string, value: string) => {
    const newAmenities = amenitiesList.map(amenity =>
        amenity.id === id ? { ...amenity, value } : amenity
    );
    setAmenitiesList(newAmenities);
  };

  const handleRemoveAmenity = () => {
    setAmenitiesList(amenitiesList.slice(0, -1));
    setAmenityRemoved(true);
  };

  return (
    <Dialog
      className="pit-edit-form"
      title={t('room-types.edit-form.title', { level: t(`luxury-level.${luxuryLevel}`) })}
      onClose={cancelEdit}
    >
      {loading && (
        <div className="loading-spinner">
          <InfinitySpin color="var(--font-color)" />
        </div>
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        validator={validator}
        render={({ allowSubmit, visited, errors, onChange, valueGetter }) => (
          <FormElement>
            <fieldset className="k-form-fieldset edit-form">
              {visited && errors?.VALIDATION_SUMMARY && (
                <div className="k-messagebox k-messagebox-error">{errors.VALIDATION_SUMMARY}</div>
              )}
              <div className="form-item">
                <Label>{t('room-types.description')}</Label>
                <div className="k-form-field">
                  <Field
                    name="description"
                    component={ValidatedTextArea}
                  />
                </div>
              </div>
              <div className="form-item">
                <Label>{t('room-types.amenities')}</Label>
                <div className="k-form-field rows">
                  {amenitiesList.map((amenity, index) => (
                      <Field
                          name={`amenities[${index}]`}
                          component={ValidatedInput}
                          onChange={(e) => handleAmenityChange(amenity.id, e.target.value)}
                      />
                  ))}
                </div>
                <div className="k-form-buttons">
                  <button
                      type="button"
                      onClick={handleAddAmenity}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  > +
                  </button>
                  <button
                      type="button"
                      onClick={handleRemoveAmenity}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  > -
                  </button>
                </div>
              </div>
              <div className="columns">
                <div className="form-item">
                  <Label>{t('room-types.capacity')}</Label>
                  <div className="k-form-field">
                    <Field
                        name="capacity"
                          component={ValidatedNumericInput}
                      />
                    </div>
                  </div>
                  <div className="form-item">
                    <Label>{t('room-types.price')}</Label>
                    <div className="k-form-field">
                      <Field
                          name="pricePerNight"
                          component={ValidatedNumericInput}
                      />
                    </div>
                  </div>
                </div>
                <div className="resources">
                  <ImagesList
                      onChange={onChange}
                      valueGetter={valueGetter}
                      resourceLinks={resourceLinks}
                  />
                </div>
            </fieldset>
            {error && (
              <div className="k-messagebox k-messagebox-error">
                <h4>{error.title}</h4>
                {error &&
                  error.messages &&
                  Object.keys(error.messages).map((key) => <div key={key}>{error.messages![key]}</div>)}
              </div>
            )}
            <div className="k-form-buttons">
              <button
                type="submit"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                disabled={!amenityRemoved && !allowSubmit}
              >
                {t('generic.update')}
              </button>
              <button
                type="submit"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={cancelEdit}
              >
                {t('generic.cancel')}
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default EditForm;
