import { ReactNode } from 'react';

type PropsType = {
  children: ReactNode[];
  header?: string[];
};

const DisplayGrid = ({ children, header = [] }: PropsType) => (
  <div className="k-grid">
    <table className="k-table k-grid-table k-table-md">
      {header && header.length > 0 && (
        <thead className="k-table-thead">
          <tr className="k-table-row">
            {header.map((title) => (
              <th
                key={title}
                className="k-table-th k-header"
              >
                {title}
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody className="k-table-tbody">{children}</tbody>
    </table>
  </div>
);

export default DisplayGrid;
