import { createBrowserRouter } from 'react-router-dom';

import App from './app';
import ErrorPage from './features/error/pages/error-page';
import NotFound from './features/error/pages/not-found';
import ProtectedRoute from './features/auth/protected-route';
import Auth0ProviderWithHistory from './features/auth/auth0-provider-with-history';
import AccommodationsPage from './features/accommodations/pages/accommodations-page';
import BookingsPage from './features/bookings/pages/bookings-page';
import BookingDetailsPage from './features/bookings/pages/booking-details-page';
import DelegationsPage from './features/delegations/pages/delegations-page';
import RoomTypesPage from './features/accommodations/pages/room-types-page';

const protectedRoutes = [
  {
    path: '*',
    element: <NotFound />,
  },
  {
    path: '/',
    element: <DelegationsPage />,
  },
  {
    path: '/delegations',
    element: <DelegationsPage />,
  },
  {
    path: '/bookings',
    element: <BookingsPage />,
  },
  {
    path: '/bookings/:id',
    element: <BookingDetailsPage />,
  },
  {
    path: '/accommodations',
    element: <AccommodationsPage />,
  },
  {
    path: '/accommodations/:id/rooms/:name',
    element: <RoomTypesPage />,
  },
];

const router: {
  path: string;
  element: JSX.Element;
  children?: { path: string; element: JSX.Element }[] | undefined;
  errorElement?: JSX.Element | undefined;
}[] = [
  {
    path: '/error',
    element: <ErrorPage />,
  },
  {
    path: '/',
    element: (
      <Auth0ProviderWithHistory>
        <ProtectedRoute component={App} />
      </Auth0ProviderWithHistory>
    ),
    children: protectedRoutes,
    errorElement: <ErrorPage />,
  },
];

export default createBrowserRouter(router);
