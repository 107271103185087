import { FC } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Label } from '@progress/kendo-react-labels';
import { useTranslation } from 'react-i18next';
import { InfinitySpin } from 'react-loader-spinner';

import { AccommodationType } from '../../../../../shared/types/types';
import { EditFormProps } from '../types';
import ValidatedInput from '../../../../../shared/components/form/validated-input/validated-input';
import ValidatedTextArea from '../../../../../shared/components/form/validated-textarea/validated-textarea';
import ValidatedNumericInput from '../../../../../shared/components/form/validated-numeric-input/validated-numeric-input';
import ImagesList from '../components/images-list';
import validator from './validator';

import '../css/edit-form.scss';

const EditForm: FC<EditFormProps<AccommodationType>> = ({
  item,
  cancelEdit,
  onSubmit,
  error = undefined,
  loading = false,
}) => {
  const { name, resourceLinks } = item;
  const { t } = useTranslation();

  return (
    <Dialog
      className="pit-edit-form"
      title={t('accommodations.title', { name })}
      onClose={cancelEdit}
    >
      {loading && (
        <div className="loading-spinner">
          <InfinitySpin color="var(--font-color)" />
        </div>
      )}
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        validator={validator}
        render={({ allowSubmit, visited, errors: formErrors, onChange, valueGetter }) => (
          <FormElement>
            <fieldset className="k-form-fieldset edit-form">
              {visited && formErrors?.VALIDATION_SUMMARY && (
                <div className="k-messagebox k-messagebox-error">{formErrors.VALIDATION_SUMMARY}</div>
              )}
              <div className="form-item">
                <Label>{t('accommodations.name')}</Label>
                <div className="k-form-field">
                  <Field
                    name="name"
                    component={ValidatedInput}
                  />
                </div>
              </div>
              <div className="form-item">
                <Label>{t('accommodations.description')}</Label>
                <div className="k-form-field">
                  <Field
                    name="description"
                    component={ValidatedTextArea}
                  />
                </div>
              </div>
              <div className="form-item">
                <Label>{t('accommodations.website')}</Label>
                <div className="k-form-field">
                  <Field
                    name="website"
                    component={ValidatedInput}
                  />
                </div>
              </div>
              <div className="form-item">
                <Label>{t('accommodations.email')}</Label>
                <div className="k-form-field">
                  <Field
                    name="email"
                    component={ValidatedInput}
                  />
                </div>
              </div>
              <div className="form-item">
                <Label>{t('accommodations.phone-number')}</Label>
                <div className="k-form-field">
                  <Field
                    name="phoneNumber"
                    component={ValidatedInput}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="form-item">
                  <Label>{t('accommodations.city')}</Label>
                  <div className="k-form-field">
                    <Field
                      name="address.city"
                      component={ValidatedInput}
                    />
                  </div>
                </div>
                <div className="form-item form-item--small">
                  <Label>{t('accommodations.postal-code')}</Label>
                  <div className="k-form-field">
                    <Field
                      name="address.postalCode"
                      component={ValidatedInput}
                    />
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="form-item">
                  <Label>{t('accommodations.street')}</Label>
                  <div className="k-form-field">
                    <Field
                      name="address.street"
                      component={ValidatedInput}
                    />
                  </div>
                </div>
                <div className="form-item form-item--small">
                  <Label>{t('accommodations.number')}</Label>
                  <div className="k-form-field">
                    <Field
                      name="address.number"
                      component={ValidatedNumericInput}
                    />
                  </div>
                </div>
                <div className="form-item form-item--small">
                  <Label>{t('accommodations.number-suffix')}</Label>
                  <div className="k-form-field">
                    <Field
                      name="address.numberSuffix"
                      component={ValidatedInput}
                    />
                  </div>
                </div>
              </div>
              <div className="resources">
                <ImagesList
                  onChange={onChange}
                  valueGetter={valueGetter}
                  resourceLinks={resourceLinks}
                />
              </div>
            </fieldset>
            {error && (
              <div className="k-messagebox k-messagebox-error">
                <h4>{error.title}</h4>
                {error &&
                  error.messages &&
                  Object.keys(error.messages).map((key) => <div key={key}>{error.messages![key]}</div>)}
              </div>
            )}
            <div className="k-form-buttons">
              <button
                type="submit"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                disabled={!allowSubmit}
              >
                {t('generic.update')}
              </button>
              <button
                type="submit"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={cancelEdit}
              >
                {t('generic.cancel')}
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default EditForm;
