import { useTranslation } from 'react-i18next';

import DisplayGrid from '../display-grid/display-grid';
import DisplayGridRecord from '../display-grid/display-grid-record';
import { RoomBookingType } from '../../types';

type PropsType = {
  bookingRooms: RoomBookingType[];
};

const BookingRoomsGrid = ({ bookingRooms }: PropsType) => {
  const { t } = useTranslation();

  return (
    <DisplayGrid header={[t('booking-details.sub-booking.room-type'), t('booking-details.sub-booking.count')]}>
      {bookingRooms.map(({ id: roomId, roomCount, luxuryLevel }) => (
        <DisplayGridRecord
          key={`${roomId}_${luxuryLevel}`}
          label={t(`luxury-level.${luxuryLevel}`)}
        >
          {roomCount}
        </DisplayGridRecord>
      ))}
    </DisplayGrid>
  );
};

export default BookingRoomsGrid;
