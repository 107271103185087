import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@progress/kendo-react-dialogs';

import { DelegationType } from '../../types';
import DisplayGrid from '../../../bookings/components/display-grid/display-grid';
import DisplayGridRecord from '../../../bookings/components/display-grid/display-grid-record';

import './contact-details.scss';

type ContactDetailsProps = {
  onClose: () => void;
  item: DelegationType;
};

const ContactDetails = ({ item, onClose }: ContactDetailsProps) => {
  const { bemClassName } = useBem('contact-details');
  const { delegationCode, emailAddress, phoneNumber } = item;
  const { t } = useTranslation();

  return (
    <Dialog
      className={bemClassName()}
      title={t('delegations.contact-details.title', { delegation: delegationCode })}
      onClose={onClose}
    >
      <DisplayGrid>
        <DisplayGridRecord label={t('delegations.email-address')}>{emailAddress}</DisplayGridRecord>
        <DisplayGridRecord label={t('delegations.phone-number')}>{phoneNumber}</DisplayGridRecord>
      </DisplayGrid>
    </Dialog>
  );
};

export default ContactDetails;
