import React, { useState } from 'react';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';

import Page from '../../../shared/components/layout/page/page';
import { PageThemeEnum } from '../../../shared/components/layout/page/types';
import DataLoader from '../../../shared/components/grid/data-loader/data-loader';
import { FormErrorType, RoomType } from '../../../shared/types/types';
import EditForm from '../components/edit-form/room-types/edit-form';
import CommandCell from '../../../shared/components/grid/cells/command-cell';
import PriceCell from '../../../shared/components/grid/cells/price-cell';
import { deleteFiles, get, put, saveFiles, updateFiles } from '../data/room-types-service';
import prepareForSubmit from '../data/submit-handler';
import LuxuryCell from '../../../shared/components/grid/cells/luxury-cell';

const RoomTypesPage = () => {
  const { bemClassName } = useBem('rooms-page');
  const { t } = useTranslation();
  const { id, name } = useParams();

  const [openForm, setOpenForm] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formError, setFormErrors] = React.useState<FormErrorType | undefined>();
  const [editItem, setEditItem] = React.useState<RoomType>();
  const [roomTypes, setRoomTypes] = useState<DataResult>({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
    sort: [{ field: 'luxuryLevel', dir: 'asc' }],
  });

  const enterEdit = (item: RoomType) => {
    setOpenForm(true);
    setEditItem(item);
  };

  const updateAction = async (room: RoomType) => {
    const result = await put(id!, room);

    roomTypes.data = roomTypes.data.map((item) => {
      if (room.id === item.id) {
        // eslint-disable-next-line no-param-reassign
        item = { ...result };
      }
      return item;
    });

    setRoomTypes({ ...roomTypes });
  };

  const handleSubmit = async (room: RoomType) => {
    setLoading(true);
    const { item, filesToAdd, filesToUpdate, filesToRemove } = prepareForSubmit<RoomType>(room);
    const { id: roomId } = item;

    if (filesToRemove.length > 0) {
      await deleteFiles(id!, roomId, filesToRemove);
    }

    if (filesToAdd.length > 0) {
      await saveFiles(id!, roomId, filesToAdd);
    }

    if (filesToUpdate.length > 0) {
      await updateFiles(id!, roomId, filesToUpdate);
    }

    await updateAction(item)
      .then(() => setOpenForm(false))
      .catch((error) => {
        const {
          data: { errors, detail },
        } = error as { data: { errors: { [k in string]: string[] }; detail: string } };
        setFormErrors({
          title: detail,
          messages: errors,
        });
      })
      .finally(() => setLoading(false));
  };

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const dataReceived = (dataResult: DataResult) => {
    if (dataResult.data) {
      setRoomTypes({ ...dataResult });
    } else {
      setRoomTypes({ data: [], total: 0 });
    }
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const gridCommandCell = (gridCellProps: GridCellProps) => {
    const { dataItem } = gridCellProps;
    const actions = [
      {
        text: t('generic.edit'),
        action: () => enterEdit(dataItem as RoomType),
      },
    ];

    return (
      <CommandCell
        gridCellProps={gridCellProps}
        actions={actions}
      />
    );
  };

  const getRooms = async (searchParams: string) => get(id!, searchParams);

  return (
    <Page
      theme={PageThemeEnum.Default}
      className={bemClassName()}
      showNavigation
    >
      <h1 className={bemClassName('title')}>{t('room-types.title', { accommodation: name })}</h1>
      <div className={bemClassName('content')}>
        <Grid
          {...dataState}
          data={roomTypes}
          sortable
          onDataStateChange={dataStateChange}
        >
          <GridColumn
            field="luxuryLevel"
            title={t('room-types.luxury')}
            cell={LuxuryCell}
            width={200}
          />
          <GridColumn
            field="description"
            title={t('room-types.description')}
          />
          <GridColumn
            field="capacity"
            title={t('room-types.capacity')}
            width={100}
          />
          <GridColumn
            field="pricePerNight"
            cell={PriceCell}
            title={t('room-types.price')}
            width={200}
          />
          <GridColumn
            cell={gridCommandCell}
            title=""
            width={70}
            filterable={false}
          />
        </Grid>

        {openForm && (
          <EditForm
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            item={editItem!}
            error={formError}
            loading={loading}
          />
        )}

        <DataLoader
          get={getRooms}
          dataState={dataState}
          onDataReceived={dataReceived}
        />
      </div>
    </Page>
  );
};

export default RoomTypesPage;
