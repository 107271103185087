import useBem from '@indicia/use-bem';
import { PageContentType } from '../../types';
import { Container } from '../../../../index';

import './page-content.scss';

const PageContent = ({ className, children, theme }: PageContentType) => {
  const { bemClassName, bemClassNames } = useBem('page-content');

  return (
    <main className={bemClassNames([bemClassName(), bemClassName(null, className, !!className)])}>
      <Container theme={theme}>{children}</Container>
    </main>
  );
};

export default PageContent;
