import { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';

import CustomGridCell from './custom-grid-cell';
import { CommandCellActionType } from './types';

import './command-cell.scss';

type CommandCellProps = {
  gridCellProps: GridCellProps;
  actions: CommandCellActionType[];
};

const CommandCell = ({ gridCellProps, actions }: CommandCellProps) => (
  <CustomGridCell gridCellProps={gridCellProps}>
    <div className="command-cell">
      {actions.map(({ text, action }: CommandCellActionType) => (
        <Button
          key={text}
          className="k-button k-button-solid-primary"
          onClick={action}
          type="button"
          aria-label={text}
        >
          {text}
        </Button>
      ))}
    </div>
  </CustomGridCell>
);

export default CommandCell;
