import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';

import Page from '../../../shared/components/layout/page/page';
import { PageThemeEnum } from '../../../shared/components/layout/page/types';
import DataLoader from '../../../shared/components/grid/data-loader/data-loader';
import DateCell from '../../../shared/components/grid/cells/date-cell';
import get from '../data/delegations-service';
import CommandCell from '../../../shared/components/grid/cells/command-cell';
import { CommandCellActionType } from '../../../shared/components/grid/cells/types';
import ContactDetails from '../components/contact-details/contact-details';
import { DelegationType } from '../types';

const RoomsPage = () => {
  const { bemClassName } = useBem('delegations-page');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<DelegationType>();
  const [contactPopupOpen, setContactPopupOpen] = useState(false);

  const [delegations, setDelegations] = useState<DataResult>({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
    sort: [{ field: 'delegationCode', dir: 'asc' }],
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const dataReceived = (dataResult: DataResult) => {
    if (dataResult.data) {
      setDelegations({ ...dataResult });
    } else {
      setDelegations({ data: [], total: 0 });
    }
  };

  const gridCommandCell = (gridCellProps: GridCellProps) => {
    const { dataItem } = gridCellProps;
    const actions: CommandCellActionType[] = [
      {
        text: t('delegations.contact-details.button'),
        action: () => {
          setSelectedItem(dataItem);
          setContactPopupOpen(true);
        },
      },
      {
        text: t('delegations.booking'),
        action: () => {
          navigate(`/bookings/${dataItem.bookingId}`);
        },
      },
    ];

    return (
      <CommandCell
        gridCellProps={gridCellProps}
        actions={actions}
      />
    );
  };

  return (
    <Page
      theme={PageThemeEnum.Default}
      className={bemClassName()}
      showNavigation
    >
      <h1 className={bemClassName('title')}>{t('delegations.title')}</h1>
      <div className={bemClassName('content')}>
        <Grid
          filterable
          sortable
          pageable
          {...dataState}
          data={delegations}
          onDataStateChange={dataStateChange}
        >
          <GridColumn
            field="delegationCode"
            title={t('delegations.delegation-code')}
          />
          <GridColumn
            field="bookingStatus"
            width={140}
            title={t('delegations.booking-status.grid-title')}
          />
          <GridColumn
            field="loginCount"
            width={120}
            title={t('delegations.login-count')}
            filterable={false}
          />
          <GridColumn
            field="lastLogin"
            width={175}
            title={t('delegations.last-login')}
            cell={DateCell}
            filter="date"
          />
          <GridColumn
            width={225}
            cell={gridCommandCell}
            filterable={false}
          />
        </Grid>

        {contactPopupOpen && (
          <ContactDetails
            item={selectedItem!}
            onClose={() => setContactPopupOpen(false)}
          />
        )}

        <DataLoader
          get={get}
          dataState={dataState}
          onDataReceived={dataReceived}
        />
      </div>
    </Page>
  );
};

export default RoomsPage;
