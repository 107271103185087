import { FieldRenderProps } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';

import './file-upload.scss';

const FileUpload = ({ onChange, name, touched, id, validationMessage }: FieldRenderProps) => {
  const showValidationMessage: string | false | null = touched && validationMessage;
  const errorId: string = showValidationMessage ? `${id}_error` : '';

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    const reader = new FileReader();

    reader.onloadend = () => {
      onChange({ target: name, value: file });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="file-upload">
      <label
        htmlFor={name}
        aria-label={name}
      >
        <input
          name={name}
          id={name}
          type="file"
          onChange={handleFileChange}
        />
      </label>
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  );
};

export default FileUpload;
