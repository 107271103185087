import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { BookingType } from '../types';
import { getById } from './bookings-service';
import { ErrorResponse } from '../../../shared/types/types';

const useBooking = (id: string): UseQueryResult<BookingType, ErrorResponse> =>
  useQuery({
    queryKey: ['booking', id],
    queryFn: () => getById(id),
  });

export default useBooking;
