import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useBem from '@indicia/use-bem';

import useBooking from '../data/use-booking';
import Loader from '../../../shared/components/loader/loader';
import Page from '../../../shared/components/layout/page/page';
import { PageThemeEnum } from '../../../shared/components/layout/page/types';
import MainBookingDetails from '../components/main-booking-details/main-booking-details';
import AccommodationBookingList from '../components/accommodation-booking-list/accommodation-booking-list';

const BookingDetailsPage = () => {
  const { bemClassName } = useBem('booking-details');
  const { id } = useParams();
  const { t } = useTranslation();
  const { isLoading, data, error } = useBooking(id!);
  const { accommodationBookings, delegationCode } = data || { subBookings: [], delegationCode: '' };

  return (
    <Page
      theme={PageThemeEnum.Default}
      className={bemClassName()}
      showNavigation
    >
      {isLoading && <Loader />}
      {!isLoading && data && (
        <>
          <h1 className={bemClassName('title')}>{t('booking-details.title', { delegationCode })}</h1>
          <MainBookingDetails booking={data} />
          <AccommodationBookingList bookings={accommodationBookings} />
        </>
      )}
      {!isLoading && error?.status === 404 && <h1>{t('booking-details.not-found')}</h1>}
    </Page>
  );
};

export default BookingDetailsPage;
