import useBem from '@indicia/use-bem';
import { ContainerType } from './types';
import { PageThemeEnum } from '../page/types';

import './container.scss';

const Container = ({ children, theme = PageThemeEnum.Default }: ContainerType) => {
  const { bemClassName, bemClassNames } = useBem('container');

  return <div className={bemClassNames([bemClassName(), bemClassName(null, theme)])}>{children}</div>;
};

export default Container;
