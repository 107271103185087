import { FC, useState } from 'react';
import ImageContainer from './image-container';
import { ResourceLinkType } from '../../../../../shared/types/types';

type PropType = {
  onChange: (name: string, options: { value: any }) => void;
  valueGetter: (name: string) => any;
  resourceLinks?: ResourceLinkType[];
};

const ImagesList: FC<PropType> = ({ onChange, valueGetter, resourceLinks = undefined }) => {
  const imagesToRender = ['file1', 'file2', 'file3'];
  const findResource = (id: number) => resourceLinks!.find(({ id: resourceId }) => resourceId === id) !== undefined;

  const [showImage1, setShowImage1] = useState(findResource(1));
  const [showImage2, setShowImage2] = useState(findResource(2));
  const [showImage3, setShowImage3] = useState(findResource(3));
  const [fileData1, setFileData1] = useState<string>();
  const [fileData2, setFileData2] = useState<string>();
  const [fileData3, setFileData3] = useState<string>();

  const renderImageContainers = (imageName: string, index: number) => {
    let showImageValue;
    let fileDataValue;
    let setShowImageCallback;
    let setFileDataCallback;

    switch (index + 1) {
      case 3:
        showImageValue = showImage3;
        setShowImageCallback = setShowImage3;
        fileDataValue = fileData3;
        setFileDataCallback = setFileData3;
        break;
      case 2:
        showImageValue = showImage2;
        setShowImageCallback = setShowImage2;
        fileDataValue = fileData2;
        setFileDataCallback = setFileData2;
        break;
      case 1:
      default:
        showImageValue = showImage1;
        setShowImageCallback = setShowImage1;
        fileDataValue = fileData1;
        setFileDataCallback = setFileData1;
        break;
    }

    return (
      <ImageContainer
        key={imageName}
        index={index}
        imageName={imageName}
        onChange={onChange}
        valueGetter={valueGetter}
        resourceLinks={resourceLinks ?? []}
        setShowImage={setShowImageCallback}
        setFileData={setFileDataCallback}
        showImage={showImageValue}
        fileData={fileDataValue}
      />
    );
  };

  return <>{imagesToRender.map((imageName, index) => renderImageContainers(imageName, index))}</>;
};

export default ImagesList;
