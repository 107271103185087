import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';

import AccommodationBookingDetails from '../accommodation-booking-details/accommodation-booking-details';
import { AccommodationBookingType } from '../../types';

import './accommodation-booking-list.scss';

type PropsType = {
  bookings?: AccommodationBookingType[];
};

const AccommodationBookingList = ({ bookings = [] }: PropsType) => {
  const { t } = useTranslation();
  const { bemClassName } = useBem('sub-bookings');

  return (
    <div className={bemClassName()}>
      <h2>{t('booking-details.subtitle')}</h2>

      {bookings.map((booking) => (
        <AccommodationBookingDetails
          key={booking.id}
          booking={booking}
          className={bemClassName('sub-booking')}
        />
      ))}
    </div>
  );
};

export default AccommodationBookingList;
