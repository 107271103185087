import { useTranslation } from 'react-i18next';
import DateFormatter from '../../../../shared/utils/format-date';
import { AccommodationBookingType } from '../../types';
import DisplayGrid from '../display-grid/display-grid';
import DisplayGridRecord from '../display-grid/display-grid-record';
import BookingRoomsGrid from '../booking-rooms-grid/booking-rooms-grid';

type PropsType = {
  booking: AccommodationBookingType;
  className: string;
};

const AccommodationBookingDetails = ({
  booking: { id, accommodationName, createdAt, updatedAt, roomTypeBookings },
  className,
}: PropsType) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <DisplayGrid>
        <DisplayGridRecord label={t('booking-details.sub-booking.id')}>{id}</DisplayGridRecord>
        <DisplayGridRecord label={t('booking-details.sub-booking.accommodation')}>
          {accommodationName}
        </DisplayGridRecord>
        <DisplayGridRecord label={t('booking-details.sub-booking.rooms')}>
          <BookingRoomsGrid bookingRooms={roomTypeBookings} />
        </DisplayGridRecord>
        <DisplayGridRecord label={t('generic.created-at')}>
          <DateFormatter
            date={createdAt}
            showTime
          />
        </DisplayGridRecord>
        <DisplayGridRecord label={t('generic.updated-at')}>
          <DateFormatter
            date={updatedAt}
            showTime
          />
        </DisplayGridRecord>
      </DisplayGrid>
    </div>
  );
};

export default AccommodationBookingDetails;
