import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useBem from '@indicia/use-bem';

import ErrorContainer from './component/error-container';
import Page from '../../../shared/components/layout/page/page';

import './error.scss';

const ErrorPage = () => {
  const { t } = useTranslation('common', { keyPrefix: 'error' });
  const navigate = useNavigate();
  const { bemClassName } = useBem('error-page');

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Page
      className={bemClassName()}
      showNavigation={false}
    >
      <ErrorContainer
        className={bemClassName('error-container')}
        title={t('title')}
        description={t('description')}
        buttonText={t('button-text')}
        buttonAction={() => navigate('/')}
      />
    </Page>
  );
};

export default ErrorPage;
