import { GridCellProps } from '@progress/kendo-react-grid';
import { useInternationalization } from '@progress/kendo-react-intl';
import CustomGridCell from './custom-grid-cell';

const PriceCell = (gridCellProps: GridCellProps) => {
  const intl = useInternationalization();
  const { dataItem, field } = gridCellProps;

  return <CustomGridCell gridCellProps={gridCellProps}>{intl.formatNumber(dataItem[field!], 'c')}</CustomGridCell>;
};

export default PriceCell;
