import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useBem from '@indicia/use-bem';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { DataResult, State } from '@progress/kendo-data-query';

import { PageThemeEnum } from '../../../shared/components/layout/page/types';
import Page from '../../../shared/components/layout/page/page';
import DataLoader from '../../../shared/components/grid/data-loader/data-loader';
import CommandCell from '../../../shared/components/grid/cells/command-cell';
import { get } from '../data/bookings-service';
import { AccommodationType } from '../../../shared/types/types';

const BookingsPage = () => {
  const navigate = useNavigate();
  const { bemClassName } = useBem('bookings-page');
  const { t } = useTranslation();

  const [bookings, setBookings] = useState<DataResult>({
    data: [],
    total: 0,
  });

  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
    sort: [{ field: 'delegationCode', dir: 'asc' }],
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const dataReceived = (dataResult: DataResult) => {
    if (dataResult.data) {
      setBookings({ ...dataResult });
    } else {
      setBookings({ data: [], total: 0 });
    }
  };

  const gridCommandCell = (gridCellProps: GridCellProps) => {
    const { dataItem } = gridCellProps;
    const { id: bookingId } = dataItem as AccommodationType;

    const actions = [
      {
        text: t('bookings.details'),
        action: () => navigate(`/bookings/${bookingId}`),
      },
    ];

    return (
      <CommandCell
        gridCellProps={gridCellProps}
        actions={actions}
      />
    );
  };

  return (
    <Page
      theme={PageThemeEnum.Default}
      className={bemClassName()}
      showNavigation
    >
      <h1 className={bemClassName('title')}>{t('bookings.title')}</h1>
      <div className={bemClassName('content')}>
        <Grid
          filterable
          sortable
          pageable
          {...dataState}
          data={bookings}
          onDataStateChange={dataStateChange}
        >
          <GridColumn
            field="delegationCode"
            width={150}
            title={t('bookings.delegation-code')}
          />
          <GridColumn
            field="amountOfPeople"
            width={150}
            title={t('bookings.amount-of-people')}
            filter="numeric"
          />
          <GridColumn
            field="primaryAccommodation"
            title={t('bookings.primary-accommodation')}
          />
          <GridColumn
            field="secondaryAccommodations"
            title={t('bookings.secondary-accommodations')}
          />
          <GridColumn
            field="bookingStatus"
            width={150}
            title={t('bookings.booking-status')}
          />
          <GridColumn
            width={90}
            cell={gridCommandCell}
            filterable={false}
          />
        </Grid>

        <DataLoader
          get={get}
          dataState={dataState}
          onDataReceived={dataReceived}
        />
      </div>
    </Page>
  );
};

export default BookingsPage;
