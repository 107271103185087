import { createPortal } from 'react-dom';
import { InfinitySpin } from 'react-loader-spinner';

import './loading-panel.scss';

const LoadingPanel = () => {
  const loadingPanel = (
    <div className="k-loading-mask">
      <div className="loading-container">
        <span className="k-loading-text">Loading</span>
        <InfinitySpin />
      </div>
    </div>
  );

  const gridContent = document && document.querySelector('.k-grid-content');
  return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
};

export default LoadingPanel;
