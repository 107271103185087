import { getter } from '@progress/kendo-data-query';
import { ErrorObjectType } from '../../../../../shared/types/types';

const nameGetter: any = getter('name');
const emailGetter: any = getter('email');
const phoneNumberGetter: any = getter('phoneNumber');
const cityGetter: any = getter('address.city');
const postalCodeGetter: any = getter('address.postalCode');
const streetGetter: any = getter('address.street');
const numberGetter: any = getter('address.number');

const validateBaseInformation = (values: any) => {
  const errorObject: ErrorObjectType = {};

  if (!nameGetter(values)) errorObject.name = 'Please fill the name field.';
  if (!emailGetter(values)) errorObject.email = 'Please fill the email field.';
  if (!phoneNumberGetter(values)) errorObject.phoneNumber = 'Please fill the phone number field.';
  if (values.address) {
    if (!cityGetter(values)) errorObject['address.city'] = 'Please fill the city field.';
    if (!postalCodeGetter(values)) errorObject['address.postalCode'] = 'Please fill the postal code field.';
    if (!streetGetter(values)) errorObject['address.street'] = 'Please fill the street field.';
    if (!numberGetter(values)) errorObject['address.number'] = 'Please fill the number field.';
    if (!Number.isInteger(Number(numberGetter(values))))
      errorObject['address.number'] = 'Please fill the number field with a number.';
  }

  return errorObject;
};

const validator = (values: any) => {
  const errorObject: { [k in string]: string } = {
    VALIDATION_SUMMARY:
      'Please ensure all fields are filled and meet the validation standards. Check the highlighted fields for any issues.',
  };

  if (nameGetter(values) && emailGetter(values) && phoneNumberGetter(values) && values.address !== null) {
    if (cityGetter(values) && postalCodeGetter(values) && streetGetter(values) && numberGetter(values)) return;
  }

  // eslint-disable-next-line consistent-return
  return {
    ...errorObject,
    ...validateBaseInformation(values),
  };
};

export default validator;
