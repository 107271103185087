import { GridCellProps } from '@progress/kendo-react-grid';
import CustomGridCell from './custom-grid-cell';

import './text-cell.scss';

const PriceCell = (gridCellProps: GridCellProps) => {
  const { dataItem, field } = gridCellProps;

  return (
    <CustomGridCell gridCellProps={gridCellProps}>
      <div className="text-cell">{dataItem[field!]}</div>
    </CustomGridCell>
  );
};

export default PriceCell;
