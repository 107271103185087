import http from '../../../shared/utils/http-client';
import { BookingType } from '../types';

const get = async (searchParams: string) => http.get(`bookings?${searchParams}`);

const getById = async (id: string): Promise<BookingType> => {
  const { data } = await http.get(`bookings/${id}`);
  return data;
};

export { get, getById };
