import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

type PropType = {
  children: any;
};

const Auth0ProviderWithHistory = ({ children }: PropType): JSX.Element => {
  const domain = window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_DOMAIN;
  const clientId = window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_CLIENT_ID;
  const navigator = useNavigate();

  const {
    location: { pathname },
  } = window;

  const onRedirectCallback = (appState: any) => {
    navigator(appState?.returnTo || pathname);
  };

  const providerConfig = {
    domain,
    clientId,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_AUDIENCE,
    },
  };

  return (
    <Auth0Provider
      {...providerConfig}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
