import { ReactNode } from 'react';

type PropsType = {
  label: string;
  children: ReactNode;
};

const DisplayGridRecord = ({ label, children }: PropsType) => (
  <tr className="k-table-row">
    <td className="k-table-td">{label}</td>
    <td className="k-table-td">{children}</td>
  </tr>
);

export default DisplayGridRecord;
