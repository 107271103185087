import React, { FC } from 'react';
import { Button } from '../../../../shared/components';

type PropType = {
  className?: string;
  title: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
};

const ErrorContainer: FC<PropType> = ({ buttonAction, buttonText, title, description, className = undefined }) => (
  <div className={className}>
    <h1>{title}</h1>
    <p>{description}</p>
    <Button onClick={buttonAction}>{buttonText}</Button>
  </div>
);

export default ErrorContainer;
