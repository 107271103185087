import { getter } from '@progress/kendo-data-query';
import { ErrorObjectType } from '../../../../../shared/types/types';

const descriptionGetter: any = getter('description');
const capacityGetter: any = getter('capacity');
const priceGetter: any = getter('pricePerNight');

const validateBaseInformation = (values: any) => {
  const errorObject: ErrorObjectType = {};

  if (!descriptionGetter(values)) errorObject.description = 'Please fill the description field.';
  if (!capacityGetter(values) && capacityGetter(values) !== 0) errorObject.capacity = 'Please fill the capacity field.';
  if (!priceGetter(values)) errorObject.pricePerNight = 'Please fill the price per night field.';

  return errorObject;
};

const validator = (values: any) => {
  const errorObject: { [k in string]: string } = {
    VALIDATION_SUMMARY:
      'Please ensure all fields are filled and meet the validation standards. Check the highlighted fields for any issues.',
  };

  if (
    descriptionGetter(values) &&
    (capacityGetter(values) || capacityGetter(values) === 0) &&
    (priceGetter(values) || priceGetter(values) === 0)
  ) {
    return;
  }

  // eslint-disable-next-line consistent-return
  return {
    ...errorObject,
    ...validateBaseInformation(values),
  };
};

export default validator;
